// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reviews-45th-stop-n-shop-and-poke-bar-mdx": () => import("./../../../src/pages/reviews/45th_stop_n_shop_and_poke_bar.mdx" /* webpackChunkName: "component---src-pages-reviews-45th-stop-n-shop-and-poke-bar-mdx" */),
  "component---src-pages-reviews-beyond-the-bowl-mdx": () => import("./../../../src/pages/reviews/beyond_the_bowl.mdx" /* webpackChunkName: "component---src-pages-reviews-beyond-the-bowl-mdx" */),
  "component---src-pages-reviews-fob-poke-bar-mdx": () => import("./../../../src/pages/reviews/fob_poke_bar.mdx" /* webpackChunkName: "component---src-pages-reviews-fob-poke-bar-mdx" */),
  "component---src-pages-reviews-hiroshis-poke-mdx": () => import("./../../../src/pages/reviews/hiroshi's_poke.mdx" /* webpackChunkName: "component---src-pages-reviews-hiroshis-poke-mdx" */),
  "component---src-pages-reviews-just-poke-fremont-mdx": () => import("./../../../src/pages/reviews/just_poke_fremont.mdx" /* webpackChunkName: "component---src-pages-reviews-just-poke-fremont-mdx" */),
  "component---src-pages-reviews-ono-poke-mdx": () => import("./../../../src/pages/reviews/ono_poke.mdx" /* webpackChunkName: "component---src-pages-reviews-ono-poke-mdx" */),
  "component---src-pages-reviews-poke-lover-mdx": () => import("./../../../src/pages/reviews/poke_lover.mdx" /* webpackChunkName: "component---src-pages-reviews-poke-lover-mdx" */),
  "component---src-pages-reviews-poke-square-mdx": () => import("./../../../src/pages/reviews/poke_square.mdx" /* webpackChunkName: "component---src-pages-reviews-poke-square-mdx" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

